/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const smoothScroll = function (location) {
  if (!location.hash) {
    return true;
  }
  const elem = document.getElementById(location.hash);
  if (!elem) return;
  elem.scrollIntoView({ behavior: "smooth", alignToTop: false });
  return false;
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  return smoothScroll(location);
};

export const onRouteUpdate = ({ location }, ...rest) => smoothScroll(location);
