export default {
  colors: {
    text: "#fff",
    background: "#000",
    primary: "#fff",
    secondary: "#154DFF",
    gray: "#828282",
  },
  fontSizes: [14, 24, 32, 48, 64, 72, 110],
  lineHeights: ["131%"],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  borders: {
    solid: "solid",
  },
  borderWidths: {
    s: "1px",
    m: "2px",
  },
  space: [0, 4, 8, 16, 32, 64, 72, 88, 96, 128, 256, 512],
  styles: {
    a: {
      color: "primary",
      textDecoration: "none",
    },
    img: {
      margin: "0",
    },
    h1: {
      fontSize: ["48px", "64px"],
      lineHeight: "130%",
    },
    h2: {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginInlineStart: 0,
    },
    button: {},
  },
  breakpoints: ["767px", "1024px", "1440px", "1800px"],
};
